import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { connect } from 'react-redux';
import LinkingConfiguration from './LinkingConfiguration';

// import { MaterialCommunityIcons } from '@expo/vector-icons';
// import { navigationRef } from './NavigationHelper';
import Environment, { FeatureFlags } from '../config/Environment';
import Colors from '../constants/Colors';
import MainTabNavigator from './MainTabNavigator';
import LandingScreen from '../screens/LandingScreen';
import ComingSoonScreen from '../screens/ComingSoonScreen';
import LoadingScreen from '../screens/LoadingScreen';
import LoginScreen from '../screens/LoginScreen';
import SignupScreen from '../screens/SignupScreen';
// import DetailedPostScreen from '../screens/DetailedPostScreen';
import { Typography } from '../constants';

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator();

export const headerConfig = {
    headerStyle: {
        backgroundColor: Colors.offWhite,
        // backgroundColor: Colors.middleBlueGreen,
        shadowColor: 'transparent',
        shadowRadius: 0,
        shadowOffset: {
            height: 0,
        },
        borderBottomWidth: 0,
        // shadowOpacity: 0,
    },
    headerTitleStyle: {
        ...Typography.scrubby,
    },
    headerBackTitleVisible: false,
    headerBackImage: () => <MaterialCommunityIcons color={Colors.blueSapphire} name='arrow-left' size={32} style={{ marginLeft: 8 }} />,
};

function RootNavigator(props) {
    return (
        <Stack.Navigator screenOptions={headerConfig}>
            {FeatureFlags.COMING_SOON_EMAIL_SUBSCRIBE ? (
                <Stack.Group>
                    <Stack.Screen
                        name='ComingSoonScreen'
                        component={ComingSoonScreen}
                        options={{
                            title: `Kingdom come`,
                        }}
                    />
                </Stack.Group>
            ) : props.auth.uid ? (
                <Stack.Group>
                    <Stack.Screen
                        name='MainTabNavigator'
                        component={MainTabNavigator}
                        options={{
                            headerShown: false,
                        }}
                    />
                    {/* <Stack.Screen
                        name='DetailedPostScreen'
                        component={DetailedPostScreen}
                        options={{
                            title: 'A Piece of Love',
                            headerStyle: {
                                backgroundColor: Colors.rufous,
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                                ...Typography.h3,
                                fontWeight: undefined,
                            },
                        }}
                    /> */}
                </Stack.Group>
            ) : props.auth.isLoaded && props.auth.isEmpty ? (
                <Stack.Group>
                    <Stack.Screen
                        name='LoginScreen'
                        component={LoginScreen}
                        options={{
                            title: `Kingdom come`,
                        }}
                    />
                    <Stack.Screen
                        name='SignupScreen'
                        component={SignupScreen}
                        options={{
                            title: 'Signup',
                        }}
                    />
                </Stack.Group>
            ) : (
                <Stack.Screen
                    name='LoadingScreen'
                    component={LoadingScreen}
                    options={{
                        headerShown: false,
                    }}
                />
            )}
        </Stack.Navigator>
    );
}

function Navigation({ colorScheme, auth }) {
    return (
        <NavigationContainer linking={LinkingConfiguration} theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
            <RootNavigator auth={auth} />
        </NavigationContainer>
    );
}

const mapStateToProps = ({ firebase }) => {
    const { auth } = firebase;

    return {
        auth,
    };
};

export default connect(mapStateToProps, {})(Navigation);
