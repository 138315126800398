export const firebaseConfigProd = {
    apiKey: 'AIzaSyATpNXi8FESsnUO_FW2KDqqWmsbZg2ygAE',
    authDomain: 'kingdomcome-3a811.firebaseapp.com',
    projectId: 'kingdomcome-3a811',
    storageBucket: 'kingdomcome-3a811.appspot.com',
    messagingSenderId: '105977407930',
    appId: '1:105977407930:web:16462b0871ec0c59965583',
    measurementId: 'G-68XB9LZDS9',
};

export const firebaseConfigDev = {
    apiKey: 'AIzaSyDpiDhbXQInQ-Z4ucIQDiL-TLl51Z3Y1lA',
    authDomain: 'kingdomcome-dev.firebaseapp.com',
    projectId: 'kingdomcome-dev',
    storageBucket: 'kingdomcome-dev.appspot.com',
    messagingSenderId: '892797009960',
    appId: '1:892797009960:web:7b9c473b7618159e47351d',
};
