import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useState, useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Asset } from 'expo-asset';
import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import Navigation from './navigation';
import { store } from './store';
import Firebase from './config/Firebase.js';

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

const rrfProps = {
    firebase: Firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance: createFirestoreInstance,
};

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
    }),
});

SplashScreen.preventAutoHideAsync();

export default function App() {
    const [appIsReady, setAppIsReady] = useState(false);

    useEffect(() => {
        async function prepare() {
            try {
                // Pre-load fonts, make any API calls you need to do here
                await Font.loadAsync({
                    ...Ionicons.font,
                    metropolisBlack: require('./assets/fonts/metropolis/Metropolis-Black.otf'),
                    metropolisBlackItalic: require('./assets/fonts/metropolis/Metropolis-BlackItalic.otf'),
                    metropolisBold: require('./assets/fonts/metropolis/Metropolis-Bold.otf'),
                    metropolisBoldItalic: require('./assets/fonts/metropolis/Metropolis-BoldItalic.otf'),
                    metropolisExtraBold: require('./assets/fonts/metropolis/Metropolis-ExtraBold.otf'),
                    metropolisExtraBoldItalic: require('./assets/fonts/metropolis/Metropolis-ExtraBoldItalic.otf'),
                    metropolisExtraLight: require('./assets/fonts/metropolis/Metropolis-ExtraLight.otf'),
                    metropolisExtraLightItalic: require('./assets/fonts/metropolis/Metropolis-ExtraLightItalic.otf'),
                    metropolisLight: require('./assets/fonts/metropolis/Metropolis-Light.otf'),
                    metropolisLightItalic: require('./assets/fonts/metropolis/Metropolis-LightItalic.otf'),
                    metropolisMedium: require('./assets/fonts/metropolis/Metropolis-Medium.otf'),
                    metropolisMediumItalic: require('./assets/fonts/metropolis/Metropolis-MediumItalic.otf'),
                    metropolisRegular: require('./assets/fonts/metropolis/Metropolis-Regular.otf'),
                    metropolisRegularItalic: require('./assets/fonts/metropolis/Metropolis-RegularItalic.otf'),
                    metropolisSemiBold: require('./assets/fonts/metropolis/Metropolis-SemiBold.otf'),
                    metropolisSemiBoldItalic: require('./assets/fonts/metropolis/Metropolis-SemiBoldItalic.otf'),
                    metropolisThin: require('./assets/fonts/metropolis/Metropolis-Thin.otf'),
                    metropolisThinItalic: require('./assets/fonts/metropolis/Metropolis-ThinItalic.otf'),
                    loraBold: require('./assets/fonts/lora/Lora-Bold.ttf'), // 700
                    loraBoldItalic: require('./assets/fonts/lora/Lora-BoldItalic.ttf'), // 700
                    loraMedium: require('./assets/fonts/lora/Lora-Medium.ttf'), // 500
                    loraMediumItalic: require('./assets/fonts/lora/Lora-MediumItalic.ttf'), // 500
                    loraItalic: require('./assets/fonts/lora/Lora-Italic.ttf'), // 400
                    loraRegular: require('./assets/fonts/lora/Lora-Regular.ttf'), // 400
                    loraSemiBold: require('./assets/fonts/lora/Lora-SemiBold.ttf'), // 600
                    loraSemiBoldItalic: require('./assets/fonts/lora/Lora-SemiBoldItalic.ttf'), // 600
                    loraVariable: require('./assets/fonts/lora/Lora-VariableFont_wght.ttf'),
                    loraVariableItalic: require('./assets/fonts/lora/Lora-Italic-VariableFont_wght.ttf'),
                    scrubby: require('./assets/fonts/scrubby.otf'),
                });
                const images = [require('./assets/images/icon.png')];

                const cacheImages = images.map((image) => {
                    return Asset.fromModule(image).downloadAsync();
                });

                await Promise.all(cacheImages);
            } catch (e) {
                console.warn(e);
            } finally {
                // Tell the application to render
                setAppIsReady(true);
                await SplashScreen.hideAsync();
            }
        }

        prepare();
    }, []);

    // const onLayoutRootView = useCallback(async () => {
    //     if (appIsReady) {
    //         // This tells the splash screen to hide immediately! If we call this after
    //         // `setAppIsReady`, then we may see a blank screen while the app is
    //         // loading its initial state and rendering its first pixels. So instead,
    //         // we hide the splash screen once we know the root view has already
    //         // performed layout.
    //         await SplashScreen.hideAsync();
    //     }
    // }, [appIsReady]);

    if (!appIsReady) {
        return null;
    }

    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <SafeAreaProvider>
                    <Navigation />
                    <StatusBar />
                </SafeAreaProvider>
            </ReactReduxFirebaseProvider>
        </Provider>
    );
}
