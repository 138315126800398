import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { firebaseConfigProd, firebaseConfigDev } from './.Secrets';
import Environment from './Environment';

const firebaseConfig = Environment.ENVIRONMENT === 'production' ? firebaseConfigProd : firebaseConfigDev;

const firebaseCredentials = {
    apiKey: firebaseConfig.apiKey,
    authDomain: firebaseConfig.authDomain,
    databaseURL: firebaseConfig.databaseURL,
    projectId: firebaseConfig.projectId,
    storageBucket: firebaseConfig.storageBucket,
    messagingSenderId: firebaseConfig.messagingSenderId,
    appId: firebaseConfig.appId,
    measurementId: firebaseConfig.measurementId || null,
};

let Firebase;

if (firebase.apps.length === 0) {
    Firebase = firebase.initializeApp(firebaseCredentials);
} else {
    Firebase = firebase.app();
}

export const db = firebase.firestore();

export default Firebase;
