import Firebase, { db } from '../config/Firebase.js';
import { query, where, getDocs } from 'firebase/firestore';
import moment from 'moment';
// import { user } from '@primable/backend/src/models';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    UPDATE_LOGIN: 'UPDATE_LOGIN',
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    SIGNUP: 'SIGNUP',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAIL: 'SIGNUP_FAIL',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',
    EMAIL_SUBSCRIBE: 'EMAIL_SUBSCRIBE',
    EMAIL_SUBSCRIBE_SUCCESS: 'EMAIL_SUBSCRIBE_SUCCESS',
    EMAIL_SUBSCRIBE_FAIL: 'EMAIL_SUBSCRIBE_FAIL',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    email: null,
    password: null,
    user: null,
    error: '',
    loading: false,
    emailSubscribeLoading: false,
    emailSubscribeSuccess: false,
    emailSubscribeError: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_LOGIN:
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password,
            };
        case types.LOGIN:
            return {
                ...state,
                loading: true,
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        case types.LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.SIGNUP:
            return {
                ...state,
                loading: true,
            };
        case types.SIGNUP_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.SIGNUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_USER:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.EMAIL_SUBSCRIBE:
            return {
                ...state,
                emailSubscribeLoading: true,
            };
        case types.EMAIL_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                emailSubscribeLoading: false,
                emailSubscribeSuccess: true,
            };
        case types.EMAIL_SUBSCRIBE_FAIL:
            return {
                ...state,
                emailSubscribeLoading: false,
                emailSubscribeError: 'There was a problem submitting your email :[',
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const updateLogin = ({ email, password }) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_LOGIN,
            payload: {
                email,
                password,
            },
        });
    };
};

export const login = ({ email, password }) => {
    return (dispatch) => {
        try {
            dispatch({ type: types.LOGIN });

            const response = Firebase.auth()
                .signInWithEmailAndPassword(email, password)
                .then(
                    function (result) {
                        dispatch({
                            type: types.LOGIN_SUCCESS,
                            payload: result.user,
                        });
                    },
                    function (error) {
                        dispatch({
                            type: types.LOGIN_FAIL,
                            payload: error,
                        });
                        console.log(response.error);
                    }
                );
        } catch (e) {
            console.log(e);
        }
    };
};

export const signup = ({ fullName, userName, email, password }) => {
    return async (dispatch) => {
        dispatch({
            type: types.SIGNUP,
            payload: { fullName, userName, email, password },
        });

        // try {
        //     await user.validateAsync({ fullName, userName, email, password });
        // } catch (err) {
        //     console.log('err');
        //     console.log(err);
        //     return;
        // }
        try {
            Firebase.auth()
                .createUserWithEmailAndPassword(email, password)
                .then(() => {
                    if (Firebase.auth().currentUser) {
                        const user = {
                            uid: Firebase.auth().currentUser.uid,
                            email,
                            fullName,
                            userName,
                        };
                        db.collection('users')
                            .doc(Firebase.auth().currentUser.uid)
                            .set(user)
                            .then((ref) => {
                                dispatch({
                                    type: types.SIGNUP_SUCCESS,
                                });
                            })
                            .catch((err) => {
                                dispatch({
                                    type: types.SIGNUP_FAIL,
                                    payload: `Encountered error: ${err}`,
                                });
                            });
                    } else {
                        console.log('no current user');
                    }
                })
                .catch(function (error) {
                    dispatch({
                        type: types.LOGIN_FAIL,
                        payload: error,
                    });
                    console.log(error);
                });
        } catch (e) {
            console.log(e);
        }
    };
};

export const updateUser = ({ user }) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_USER,
            payload: { user },
        });

        if (Firebase.auth().currentUser) {
            db.collection('users')
                .doc(Firebase.auth().currentUser.uid)
                .update(user)
                .then(function () {
                    dispatch({
                        type: types.UPDATE_USER_SUCCESS,
                        payload: { user },
                    });
                });
        } else {
            console.log('no current user');
            dispatch({
                type: types.UPDATE_USER_FAIL,
                payload: { user },
            });
        }
    };
};

export const emailSubscribe = ({ email }) => {
    return (dispatch) => {
        try {
            const ref = db.collection('emailList').doc();
            const id = ref.id;
            let emailListObject = {};
            emailListObject.id = id;
            emailListObject.date = new Date();
            emailListObject.email = email;

            dispatch({ type: types.EMAIL_SUBSCRIBE, payload: emailListObject });

            db.collection('emailList')
                .doc(`${id}`)
                .set(emailListObject)
                .then((ref) => {
                    dispatch({
                        type: types.EMAIL_SUBSCRIBE_SUCCESS,
                        payload: ref,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: types.EMAIL_SUBSCRIBE_FAIL,
                        payload: `Encountered error: ${err}`,
                    });
                });
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.EMAIL_SUBSCRIBE_FAIL,
                payload: 'dun goofed',
            });
        }
    };
};
