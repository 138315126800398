import React from 'react';
import { View, StyleSheet, Text, SafeAreaView, TextInput, TouchableOpacity } from 'react-native';

import { Colors, Typography } from '../constants';
import { connect } from 'react-redux';
import GlobalStyles from '../constants/GlobalStyles';
import { emailSubscribe } from '../ducks/UserSessionDuck';

const styles = StyleSheet.create({
    ...GlobalStyles,
    containerSafeArea: {
        flex: 1,
        backgroundColor: Colors.offWhite,
    },
    container: {
        flex: 1,
        backgroundColor: Colors.offWhite,
        alignItems: 'center',
        paddingTop: 30,
    },
    inputBox: {
        width: '85%',
        margin: 8,
        padding: 16,
        borderColor: Colors.richBlackFogra,
        borderBottomWidth: 1,
        textAlign: 'center',
        ...Typography.subtitle1,
    },
});

class ComingSoonScreen extends React.Component {
    state = {
        email: '',
    };
    render() {
        const { email } = this.state;
        const { emailSubscribeSuccess, emailSubscribeError, emailSubscribe } = this.props;
        return (
            <SafeAreaView style={styles.containerSafeArea}>
                <View style={styles.container}>
                    <View style={styles.height32} />
                    <Text
                        style={{
                            ...Typography.h1,
                        }}
                    >
                        Coming Soon!
                    </Text>
                    <View style={styles.height32} />
                    <Text
                        style={{
                            ...Typography.h6,
                        }}
                    >
                        Enter your email to get updates and express interest in joining the community!
                    </Text>
                    <View style={styles.height32} />
                    <TextInput
                        style={styles.inputBox}
                        value={email}
                        onChangeText={(email) =>
                            this.setState({
                                email,
                            })
                        }
                        placeholder='Email'
                        autoCapitalize='none'
                        placeholderTextColor={Colors.placeholderText}
                    />
                    <View style={styles.height16} />
                    {emailSubscribeSuccess && (
                        <Text
                            style={{
                                ...Typography.h6,
                                ...styles.marginBottom8,
                            }}
                        >
                            Success!! We'll reach out with more info soon!
                        </Text>
                    )}
                    {emailSubscribeError && (
                        <Text
                            style={{
                                ...Typography.h6,
                                ...styles.marginBottom8,
                                color: Colors.rubyRed,
                            }}
                        >
                            {emailSubscribeError}
                        </Text>
                    )}
                    <TouchableOpacity
                        style={styles.button}
                        onPress={() => {
                            emailSubscribe({ email: this.state.email });
                        }}
                    >
                        <Text style={styles.buttonText}>Subscribe</Text>
                    </TouchableOpacity>
                </View>
            </SafeAreaView>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { emailSubscribeLoading, emailSubscribeSuccess, emailSubscribeError } = userSession;
    return {
        emailSubscribeLoading,
        emailSubscribeSuccess,
        emailSubscribeError,
    };
};

export default connect(mapStateToProps, {
    emailSubscribe,
})(ComingSoonScreen);
