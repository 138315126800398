import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import UserSessionReducer from './ducks/UserSessionDuck';

export default combineReducers({
    userSession: UserSessionReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
});
