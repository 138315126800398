// PROD
export default {
    ENVIRONMENT: 'production',
    VERSION: 'v0.1.0',
    LOGGER: false,
};

export const FeatureFlags = {
    HELLOWORLD: false,
    COMING_SOON_EMAIL_SUBSCRIBE: true,
};
