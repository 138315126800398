import React from 'react';
import { Text, View, Platform } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { TabBarIcon } from '../components';
import { Colors, Typography } from '../constants';
import HomeScreen from '../screens/HomeScreen';
import SettingsScreen from '../screens/SettingsScreen';
import { headerConfig } from '.';

const HomeStackNavigator = createNativeStackNavigator();

function HomeStack() {
    return (
        <HomeStackNavigator.Navigator initialRouteName={'HomeScreen'} screenOptions={headerConfig}>
            <HomeStackNavigator.Screen name='HomeScreen' component={HomeScreen} options={{ title: 'Home' }} />
        </HomeStackNavigator.Navigator>
    );
}

const SettingsStackNavigator = createNativeStackNavigator();

function SettingsStack() {
    return (
        <SettingsStackNavigator.Navigator initialRouteName={'SettingsScreen'} screenOptions={headerConfig}>
            <SettingsStackNavigator.Screen name='SettingsScreen' component={SettingsScreen} options={{ title: 'Settings' }} />
        </SettingsStackNavigator.Navigator>
    );
}

const Tab = createBottomTabNavigator();

export const Tabs = () => {
    return (
        <Tab.Navigator
            screenOptions={({ route }) => ({
                headerShown: false,
                tabBarStyle: { backgroundColor: Colors.blueSapphire, borderTopWidth: 0, borderBottomWidth: 0, paddingBottom: 15 },
            })}
        >
            <Tab.Screen
                name='HomeStack'
                component={HomeStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Home
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? (
                            <View />
                        ) : (
                            <TabBarIcon
                                focused={focused}
                                name={Platform.OS === 'ios' ? `ios-heart${focused ? '' : '-outline'}` : `md-heart${focused ? '' : '-outline'}`}
                            />
                        ),
                }}
            />
            <Tab.Screen
                name='SettingsStack'
                component={SettingsStack}
                options={{
                    tabBarLabel: ({ focused }) => (
                        <Text
                            style={{
                                ...Typography.subtitle1,
                                color: focused ? Colors.gamboge : Colors.tabIconDefault,
                            }}
                        >
                            Settings
                        </Text>
                    ),
                    tabBarIcon: ({ focused }) =>
                        Platform.OS === 'web' ? <View /> : <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-options' : 'md-options'} />,
                }}
            />
        </Tab.Navigator>
    );
};

export default Tabs;
