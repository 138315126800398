import Colors from './Colors';
import Typography from './Typography';

const GlobalStyles = {
    square4: {
        height: 4,
        width: 4,
    },
    square8: {
        height: 8,
        width: 8,
    },
    square12: {
        height: 12,
        width: 12,
    },
    square16: {
        height: 16,
        width: 16,
    },
    square20: {
        height: 20,
        width: 20,
    },
    square24: {
        height: 24,
        width: 24,
    },
    square32: {
        height: 32,
        width: 32,
    },
    square40: {
        height: 40,
        width: 40,
    },
    square48: {
        height: 48,
        width: 48,
    },
    square56: {
        height: 56,
        width: 56,
    },
    backgroundGray: {
        backgroundColor: Colors.lightGray,
    },
    flex: {
        flex: 1,
    },
    flex2: {
        flex: 2,
    },
    flex3: {
        flex: 3,
    },
    flex4: {
        flex: 4,
    },
    flex5: {
        flex: 5,
    },
    flex6: {
        flex: 6,
    },
    flex7: {
        flex: 7,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    rowCenterAlign: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    rowCenterAlignFlex: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    rowReverse: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    justifySpaceBetween: {
        justifyContent: 'space-between',
    },
    justifyFlexEnd: {
        justifyContent: 'flex-end',
    },
    justifyFlexStart: {
        justifyContent: 'flex-start',
    },
    justifySpaceEvenly: {
        justifyContent: 'space-evenly',
    },
    alignStretch: {
        alignItems: 'stretch',
    },
    alignFlexEnd: {
        alignItems: 'flex-end',
    },
    alignFlexStart: {
        alignItems: 'flex-start',
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    colCenterAlign: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    colCenterAlignFlex: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    selfCentered: {
        alignSelf: 'center',
    },
    alignCenter: {
        alignItems: 'center',
    },
    alignSelfStretch: {
        alignSelf: 'stretch',
    },
    alignSelfFlexStart: {
        alignSelf: 'flex-start',
    },
    alignSelfFlexEnd: {
        alignSelf: 'flex-end',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    wrap: {
        flexWrap: 'wrap',
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    white: {
        backgroundColor: 'white',
    },
    colorWhite: {
        color: 'white',
    },
    fullWidth: {
        width: '100%',
    },
    padding0: {
        padding: 0,
    },
    padding4: {
        padding: 4,
    },
    padding8: {
        padding: 8,
    },
    padding12: {
        padding: 12,
    },
    padding16: {
        padding: 16,
    },
    padding20: {
        padding: 20,
    },
    padding24: {
        padding: 24,
    },
    padding28: {
        padding: 28,
    },
    padding32: {
        padding: 32,
    },
    padding36: {
        padding: 36,
    },
    padding40: {
        padding: 40,
    },
    paddingTop4: {
        paddingTop: 4,
    },
    paddingTop8: {
        paddingTop: 8,
    },
    paddingTop12: {
        paddingTop: 12,
    },
    paddingTop16: {
        paddingTop: 16,
    },
    paddingTop20: {
        paddingTop: 20,
    },
    paddingTop24: {
        paddingTop: 24,
    },
    paddingTop28: {
        paddingTop: 28,
    },
    paddingTop32: {
        paddingTop: 32,
    },
    paddingTop40: {
        paddingTop: 40,
    },
    paddingBottom4: {
        paddingBottom: 4,
    },
    paddingBottom8: {
        paddingBottom: 8,
    },
    paddingBottom12: {
        paddingBottom: 12,
    },
    paddingBottom16: {
        paddingBottom: 16,
    },
    paddingBottom20: {
        paddingBottom: 20,
    },
    paddingBottom24: {
        paddingBottom: 24,
    },
    paddingBottom28: {
        paddingBottom: 28,
    },
    paddingBottom32: {
        paddingBottom: 32,
    },
    paddingBottom40: {
        paddingBottom: 40,
    },
    paddingRight4: {
        paddingRight: 4,
    },
    paddingRight8: {
        paddingRight: 8,
    },
    paddingRight12: {
        paddingRight: 12,
    },
    paddingRight16: {
        paddingRight: 16,
    },
    paddingRight20: {
        paddingRight: 20,
    },
    paddingRight24: {
        paddingRight: 24,
    },
    paddingRight28: {
        paddingRight: 28,
    },
    paddingRight32: {
        paddingRight: 32,
    },
    paddingRight40: {
        paddingRight: 40,
    },
    paddingLeft4: {
        paddingLeft: 4,
    },
    paddingLeft8: {
        paddingLeft: 8,
    },
    paddingLeft12: {
        paddingLeft: 12,
    },
    paddingLeft16: {
        paddingLeft: 16,
    },
    paddingLeft20: {
        paddingLeft: 20,
    },
    paddingLeft24: {
        paddingLeft: 24,
    },
    paddingLeft28: {
        paddingLeft: 28,
    },
    paddingLeft32: {
        paddingLeft: 32,
    },
    paddingLeft40: {
        paddingLeft: 40,
    },
    paddingVertical4: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    paddingVertical8: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    paddingVertical12: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    paddingVertical16: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    paddingVertical20: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    paddingVertical24: {
        paddingTop: 24,
        paddingBottom: 24,
    },
    paddingVertical28: {
        paddingTop: 28,
        paddingBottom: 28,
    },
    paddingVertical32: {
        paddingTop: 32,
        paddingBottom: 32,
    },
    paddingVertical40: {
        paddingTop: 40,
        paddingBottom: 40,
    },
    paddingHorizontal4: {
        paddingLeft: 4,
        paddingRight: 4,
    },
    paddingHorizontal8: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    paddingHorizontal12: {
        paddingLeft: 12,
        paddingRight: 12,
    },
    paddingHorizontal16: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    paddingHorizontal20: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    paddingHorizontal24: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    paddingHorizontal28: {
        paddingLeft: 28,
        paddingRight: 28,
    },
    paddingHorizontal32: {
        paddingLeft: 32,
        paddingRight: 32,
    },
    paddingHorizontal40: {
        paddingLeft: 40,
        paddingRight: 40,
    },
    margin4: {
        margin: 4,
    },
    margin8: {
        margin: 8,
    },
    margin12: {
        margin: 12,
    },
    margin16: {
        margin: 16,
    },
    margin20: {
        margin: 20,
    },
    margin24: {
        margin: 24,
    },
    margin28: {
        margin: 28,
    },
    margin32: {
        margin: 32,
    },
    margin36: {
        margin: 36,
    },
    margin40: {
        margin: 40,
    },
    marginTop4: {
        marginTop: 4,
    },
    marginTop8: {
        marginTop: 8,
    },
    marginTop12: {
        marginTop: 12,
    },
    marginTop16: {
        marginTop: 16,
    },
    marginTop20: {
        marginTop: 20,
    },
    marginTop24: {
        marginTop: 24,
    },
    marginTop28: {
        marginTop: 28,
    },
    marginTop32: {
        marginTop: 32,
    },
    marginTop40: {
        marginTop: 40,
    },
    marginBottom4: {
        marginBottom: 4,
    },
    marginBottom8: {
        marginBottom: 8,
    },
    marginBottom12: {
        marginBottom: 12,
    },
    marginBottom16: {
        marginBottom: 16,
    },
    marginBottom20: {
        marginBottom: 20,
    },
    marginBottom24: {
        marginBottom: 24,
    },
    marginBottom28: {
        marginBottom: 28,
    },
    marginBottom32: {
        marginBottom: 32,
    },
    marginBottom40: {
        marginBottom: 40,
    },
    marginRight4: {
        marginRight: 4,
    },
    marginRight8: {
        marginRight: 8,
    },
    marginRight12: {
        marginRight: 12,
    },
    marginRight16: {
        marginRight: 16,
    },
    marginRight20: {
        marginRight: 20,
    },
    marginRight24: {
        marginRight: 24,
    },
    marginRight28: {
        marginRight: 28,
    },
    marginRight32: {
        marginRight: 32,
    },
    marginRight40: {
        marginRight: 40,
    },
    marginLeft4: {
        marginLeft: 4,
    },
    marginLeft8: {
        marginLeft: 8,
    },
    marginLeft12: {
        marginLeft: 12,
    },
    marginLeft16: {
        marginLeft: 16,
    },
    marginLeft20: {
        marginLeft: 20,
    },
    marginLeft24: {
        marginLeft: 24,
    },
    marginLeft28: {
        marginLeft: 28,
    },
    marginLeft32: {
        marginLeft: 32,
    },
    marginLeft40: {
        marginLeft: 40,
    },
    marginVertical4: {
        marginTop: 4,
        marginBottom: 4,
    },
    marginVertical8: {
        marginTop: 8,
        marginBottom: 8,
    },
    marginVertical12: {
        marginTop: 12,
        marginBottom: 12,
    },
    marginVertical16: {
        marginTop: 16,
        marginBottom: 16,
    },
    marginVertical20: {
        marginTop: 20,
        marginBottom: 20,
    },
    marginVertical24: {
        marginTop: 24,
        marginBottom: 24,
    },
    marginVertical28: {
        marginTop: 28,
        marginBottom: 28,
    },
    marginVertical32: {
        marginTop: 32,
        marginBottom: 32,
    },
    marginVertical40: {
        marginTop: 40,
        marginBottom: 40,
    },
    marginHorizontal4: {
        marginLeft: 4,
        marginRight: 4,
    },
    marginHorizontal8: {
        marginLeft: 8,
        marginRight: 8,
    },
    marginHorizontal12: {
        marginLeft: 12,
        marginRight: 12,
    },
    marginHorizontal16: {
        marginLeft: 16,
        marginRight: 16,
    },
    marginHorizontal20: {
        marginLeft: 20,
        marginRight: 20,
    },
    marginHorizontal24: {
        marginLeft: 24,
        marginRight: 24,
    },
    marginHorizontal28: {
        marginLeft: 28,
        marginRight: 28,
    },
    marginHorizontal32: {
        marginLeft: 32,
        marginRight: 32,
    },
    marginHorizontal40: {
        marginLeft: 40,
        marginRight: 40,
    },

    height4: {
        height: 4,
    },
    height8: {
        height: 8,
    },
    height12: {
        height: 12,
    },
    height16: {
        height: 16,
    },
    height20: {
        height: 20,
    },
    height24: {
        height: 24,
    },
    height28: {
        height: 28,
    },
    height32: {
        height: 32,
    },
    height36: {
        height: 36,
    },
    height40: {
        height: 40,
    },

    width4: {
        width: 4,
    },
    width8: {
        width: 8,
    },
    width12: {
        width: 12,
    },
    width16: {
        width: 16,
    },
    width20: {
        width: 20,
    },
    width24: {
        width: 24,
    },
    width28: {
        width: 28,
    },
    width32: {
        width: 32,
    },
    width36: {
        width: 36,
    },
    width40: {
        width: 40,
    },
    borderRadius4: {
        borderRadius: 4,
    },
    borderRadius8: {
        borderRadius: 8,
    },
    borderRadius12: {
        borderRadius: 12,
    },
    borderRadius16: {
        borderRadius: 16,
    },
    borderRadius20: {
        borderRadius: 20,
    },
    round: {
        borderRadius: 1000,
    },
    opacity60: {
        opacity: 0.6,
    },
    zIndex1: {
        zIndex: 1,
    },
    zIndex2: {
        zIndex: 2,
    },
    zIndex3: {
        zIndex: 3,
    },
    zIndex4: {
        zIndex: 4,
    },
    zIndex5: {
        zIndex: 5,
    },
    zIndex6: {
        zIndex: 6,
    },
    // compund
    button: {
        marginTop: 28,
        marginBottom: 20,
        paddingVertical: 4,
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: Colors.white,
        // borderColor: Colors.rufous,
        // borderWidth: 1,
        borderRadius: 8,
        minWidth: 200,
    },
    buttonText: {
        ...Typography.button,
    },
    background: {
        flex: 1,
        backgroundColor: Colors.mediumChampagne,
    },
};

export default GlobalStyles;
